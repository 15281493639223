import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getAuth ,onAuthStateChanged} from 'firebase/auth';
import { FaBox, FaCheck, FaClock, FaTruck } from 'react-icons/fa';
import SPFooter from './SPFooter';
import NavBar from './NavBar';
import MobileNav from './MobileNav';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
);

const OrderStatus = ({ status }) => {
    const getStatusConfig = (status) => {
        const configs = {
            'pending': { icon: FaClock, color: 'text-yellow-500', bg: 'bg-yellow-100', text: 'Pending' },
            'processing': { icon: FaBox, color: 'text-blue-500', bg: 'bg-blue-100', text: 'Processing' },
            'shipped': { icon: FaTruck, color: 'text-purple-500', bg: 'bg-purple-100', text: 'Shipped' },
            'delivered': { icon: FaCheck, color: 'text-green-500', bg: 'bg-green-100', text: 'Delivered' },
            'cancelled': { icon: FaBox, color: 'text-red-500', bg: 'bg-red-100', text: 'Cancelled' }
        };
        return configs[status] || configs['pending'];
    };

    const config = getStatusConfig(status);
    const StatusIcon = config.icon;

    return (
        <div className={`inline-flex items-center px-3 py-1 rounded-full ${config.bg}`}>
            <StatusIcon className={`w-4 h-4 ${config.color} mr-2`} />
            <span className={`text-sm font-medium ${config.color}`}>{config.text}</span>
        </div>
    );
};

const OrderCard = ({ order }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="bg-white rounded-xl shadow-md overflow-hidden mb-4 transition-all duration-300 hover:shadow-lg">
            <div className="p-4 sm:p-6">
                {/* Order Header */}
                <div className="flex flex-wrap justify-between items-start mb-4">
                    <div>
                        <p className="text-sm text-gray-500">Order #{order._id.slice(-6)}</p>
                        <p className="text-sm text-gray-500">{new Date(order.createdAt).toLocaleDateString()}</p>
                    </div>
                    <OrderStatus status={order.status} />
                </div>

                {/* Order Items Preview */}
                <div className="flex flex-wrap items-center gap-4 mb-4">
                    <div className="flex -space-x-4">
                        {order.items.slice(0, 3).map((item, index) => (
                            <div key={index} className="w-12 h-12 rounded-full overflow-hidden border-2 border-white">
                                <img 
                                    src={item.image} 
                                    alt={item.name}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        ))}
                        {order.items.length > 3 && (
                            <div className="w-12 h-12 rounded-full bg-gray-100 border-2 border-white flex items-center justify-center">
                                <span className="text-sm text-gray-600">+{order.items.length - 3}</span>
                            </div>
                        )}
                    </div>
                    <div>
                        <p className="font-medium">₹{order.totalAmount.toFixed(2)}</p>
                        <p className="text-sm text-gray-500">{order.items.length} items</p>
                    </div>
                </div>

                {/* Expanded Details */}
                {isExpanded && (
                    <div className="mt-4 border-t pt-4">
                        <div className="space-y-4">
                            {order.items.map((item, index) => (
                                <div key={index} className="flex items-center gap-4">
                                    <img 
                                        src={item.image} 
                                        alt={item.name}
                                        className="w-16 h-16 rounded-lg object-cover"
                                    />
                                    <div className="flex-1">
                                        <h4 className="font-medium">{item.name}</h4>
                                        <p className="text-sm text-gray-500">
                                            Quantity: {item.quantity} × ₹{item.price.toFixed(2)}
                                        </p>
                                    </div>
                                    <div className="text-right">
                                        <p className="font-medium">₹{(item.quantity * item.price).toFixed(2)}</p>
                                    </div>
                                </div>
                            ))}
                            
                            {/* Shipping Information */}
                            <div className="border-t pt-4">
                                <h4 className="font-medium mb-2">Shipping Information</h4>
                                <p className="text-sm text-gray-600">
                                    {order.shippingAddress.address}, {order.shippingAddress.city}<br />
                                    {order.shippingAddress.state} {order.shippingAddress.pincode}<br />
                                    Phone: {order.shippingAddress.mobile}
                                </p>
                            </div>

                            {/* Status History */}
                            <div className="border-t pt-4">
                                <h4 className="font-medium mb-2">Status History</h4>
                                {order.statusHistory.map((history, index) => (
                                    <div key={index} className="text-sm text-gray-600 mb-1">
                                        {new Date(history.timestamp).toLocaleString()}: {history.status}
                                        {history.note && ` - ${history.note}`}
                                    </div>
                                ))}
                            </div>

                            <div className="border-t pt-4">
                                <div className="flex justify-between">
                                    <span className="font-medium">Total</span>
                                    <span className="font-medium">₹{order.totalAmount.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Toggle Button */}
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="mt-4 text-blue-600 hover:text-blue-800 text-sm font-medium focus:outline-none"
                >
                    {isExpanded ? 'Show less' : 'Show details'}
                </button>
            </div>
        </div>
    );
};

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('current');
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log(user)
            if (!user) {
                
                toast.error('Please login to view your orders');
                navigate('/login');
                return;
            }

            const fetchOrders = async () => {
                try {
                    setIsLoading(true);
                    const response = await axios.get('https://www.campuscart.co/api/orders/my-orders', {
                        headers: {
                            'Authorization': `Bearer ${await user.getIdToken()}`
                        }
                    });
                    setOrders(response.data.orders);
                } catch (err) {
                    setError(err.message);
                    console.error('Error fetching orders:', err);
                    toast.error('Failed to fetch orders. Please try again.');
                } finally {
                    setIsLoading(false);
                }
            };

            fetchOrders();
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, [auth, navigate]);

    const currentOrders = orders.filter(order => 
        ['pending', 'processing', 'shipped'].includes(order.status)
    );
    
    const pastOrders = orders.filter(order => 
        order.status === 'delivered' || order.status === 'cancelled'
    );

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50">
                <NavBar />
                <div className="max-w-6xl mx-auto px-4 py-8">
                    <div className="text-center py-12 text-red-600">
                        <h3 className="text-lg font-medium mb-2">Error Loading Orders</h3>
                        <p>{error}</p>
                    </div>
                </div>
                <SPFooter />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50">
            <NavBar />
            <MobileNav/>

            {/* Main Content */}
            <div className="max-w-6xl mx-auto px-4 py-8">
                {/* Tabs */}
                <div className="flex space-x-4 mb-6">
                    <button
                        className={`px-4 py-2 rounded-lg font-medium text-sm transition-colors duration-200
                            ${activeTab === 'current' 
                                ? 'bg-blue-600 text-white' 
                                : 'bg-white text-gray-600 hover:bg-gray-50'}`}
                        onClick={() => setActiveTab('current')}
                    >
                        Current Orders ({currentOrders.length})
                    </button>
                    <button
                        className={`px-4 py-2 rounded-lg font-medium text-sm transition-colors duration-200
                            ${activeTab === 'past' 
                                ? 'bg-blue-600 text-white' 
                                : 'bg-white text-gray-600 hover:bg-gray-50'}`}
                        onClick={() => setActiveTab('past')}
                    >
                        Past Orders ({pastOrders.length})
                    </button>
                </div>

                {/* Orders List */}
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="space-y-4">
                        {activeTab === 'current' ? (
                            currentOrders.length > 0 ? (
                                currentOrders.map(order => (
                                    <OrderCard key={order._id} order={order} />
                                ))
                            ) : (
                                <div className="text-center py-12">
                                    <FaBox className="w-16 h-16 text-gray-300 mx-auto mb-4" />
                                    <h3 className="text-lg font-medium text-gray-900 mb-2">No Current Orders</h3>
                                    <p className="text-gray-500">You don't have any ongoing orders at the moment.</p>
                                    <Link
                                        to="/shop"
                                        className="mt-4 inline-block px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                    >
                                        Start Shopping
                                    </Link>
                                </div>
                            )
                        ) : (
                            pastOrders.length > 0 ? (
                                pastOrders.map(order => (
                                    <OrderCard key={order._id} order={order} />
                                ))
                            ) : (
                                <div className="text-center py-12">
                                    <FaBox className="w-16 h-16 text-gray-300 mx-auto mb-4" />
                                    <h3 className="text-lg font-medium text-gray-900 mb-2">No Past Orders</h3>
                                    <p className="text-gray-500">You haven't completed any orders yet.</p>
                                    <Link
                                        to="/shop"
                                        className="mt-4 inline-block px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                    >
                                        Start Shopping
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>

            <SPFooter />
        </div>
    );
};

export default OrdersPage;