import React from 'react';
import "../styles/Ingridients.css";

import ing1 from "../assets/ing1.png";
import ing2 from "../assets/ing2.png";
import ing3 from "../assets/ing3.png";
import ing4 from "../assets/ing4.png";
import ing5 from "../assets/ing5.png";
import ing6 from "../assets/ing6.png";

const Ingredients = () => {
  const ingredients = [
    { text: "T-Shirts", image: ing1 },
    { text: "Hoodies", image: ing2 },
    { text: "Grade Cotton", image: ing3 },
    { text: "On Sale", image: ing4 },
    { text: "Botanical", image: ing6 }
  ];

  return (
    <section className="ingredients-section w-full py-8">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-medium text-center mb-6">
        OUR COLLECTION
      </h2>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 md:gap-6 max-w-7xl mx-auto px-4">
        {ingredients.map((item, index) => (
          <div key={index} className="ingredient-item flex flex-col items-center">
            <div className="relative overflow-hidden rounded-lg mb-2">
              <img
                src={item.image}
                alt={item.text}
                className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 object-cover transition-transform duration-300 hover:scale-110"
              />
            </div>
            <p className="text-xs sm:text-sm md:text-base text-center">{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Ingredients;
