import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Loader2, ChevronLeft, ChevronRight } from 'lucide-react';

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow());
  const autoPlayRef = useRef(null);
  const { id } = useParams();

  // Function to determine number of slides based on screen size
  function getSlidesToShow() {
    if (typeof window === 'undefined') return 4;
    const width = window.innerWidth;
    if (width < 640) return 1;    // sm
    if (width < 768) return 2;    // md
    if (width < 1024) return 3;   // lg
    if (width < 1280) return 4;   // xl
    return 5;                     // 2xl
  }

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = getSlidesToShow();
      setSlidesToShow(newSlidesToShow);
      setCurrentIndex(prev => Math.min(prev, products.length - newSlidesToShow));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [products.length]);

  // Fetch products
  useEffect(() => {
    const fetchYouMayLike = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://www.campuscart.co/api/items/youmaylike/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch recommendations');
        }
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchYouMayLike();
    }
  }, [id]);

  // Autoplay functionality
  useEffect(() => {
    const play = () => {
      if (!isPaused && products.length > slidesToShow) {
        setCurrentIndex(prev => {
          if (prev >= products.length - slidesToShow) {
            return 0;
          }
          return prev + 1;
        });
      }
    };

    autoPlayRef.current = setInterval(play, 2000);

    return () => {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
    };
  }, [isPaused, products.length, slidesToShow]);

  // Loading state
  if (loading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="text-lg text-red-500">Failed to load recommendations</div>
      </div>
    );
  }

  // Empty state
  if (products.length === 0) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="text-lg text-gray-500">No similar products found</div>
      </div>
    );
  }

  const visibleProducts = products.slice(
    currentIndex,
    currentIndex + slidesToShow
  );

  const nextSlide = () => {
    setCurrentIndex(prev => Math.min(products.length - slidesToShow, prev + 1));
  };

  const prevSlide = () => {
    setCurrentIndex(prev => Math.max(0, prev - 1));
  };

  return (
    <div className="w-full px-4 py-8">
      <div className="max-w-7xl mx-auto">
      
        <div 
          className="relative"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div className="overflow-hidden">
            <div 
              className="flex transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(-${(currentIndex * (100 / slidesToShow))}%)`
              }}
            >
              {products.map((product) => (
                <div 
                  key={product._id} 
                  className={`flex-none w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-2`}
                >
                  <Link
                    to={`/${product._id}`}
                    className="group block"
                  >
                    <div className="aspect-square overflow-hidden rounded-lg bg-gray-100">
                      <img
                        src={product.primaryImage}
                        alt={product.name}
                        className="w-full h-full object-contain object-center transition-transform duration-300 group-hover:scale-110"
                      />
                    </div>
                    <h3 className="mt-3 text-md text-center md:text-base text-gray-700 font-medium truncate">
                      {product.name}
                    </h3>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          {products.length > slidesToShow && (
            <>
              <button
                onClick={prevSlide}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 md:-translate-x-4 bg-white/80 hover:bg-white p-1.5 md:p-2 rounded-full shadow-lg disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
                disabled={currentIndex === 0}
              >
                <ChevronLeft className="w-4 h-4 md:w-5 md:h-5" />
              </button>
              <button
                onClick={nextSlide}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-2 md:translate-x-4 bg-white/80 hover:bg-white p-1.5 md:p-2 rounded-full shadow-lg disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
                disabled={currentIndex >= products.length - slidesToShow}
              >
                <ChevronRight className="w-4 h-4 md:w-5 md:h-5" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;