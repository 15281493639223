import React from 'react'
import "../styles/GiftSets.css";
import HoverImage from 'react-hover-image/build';
import { Link } from 'react-router-dom';
import  { useState, useEffect } from 'react';

import axios from 'axios';

// import { Loader2 } from 'lucide-react';
const GiftSets = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchGiftSets = async () => {
          try {
            setLoading(true);
            const response = await axios.get('https://www.campuscart.co/api/bestseller?limit=3');
            
            setProducts(response.data.data?response.data.data.map(data=>(data.item)):[]);
            setError(null);
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        };
    
        fetchGiftSets();
      }, []);  
      return (
        <div className='giftSetMainParent'>

            <p className='categoryText font-bold'> CATEGORY </p>

            <div className='flex flex-col gap-8 bestSellerPriceHolder'>

                <Link to={"/category/under500"} >
                    <p className="hover:underline"> Under ₹500 </p>
                </Link>

                <Link to={"/category/forher"} >
                    <p className="hover:underline"> For Her </p>
                </Link>

                <Link to={"/category/forhim"} >
                    <p className="hover:underline"> For Him </p>
                </Link>
            </div>

            <div className={`flex flex-wrap justify-center gap-20 giftHold ${products.length > 2 ? 'flex-row' : 'flex-col'}`}>

                {products.map((item) => (
                    <Link to={`/${item.id}`} key={item.id}>
                        <div className="giftIndivitual flex flex-col items-center">
                            <div className="w-48 h-48 relative">
                                <HoverImage
                                    src={item.primaryImage}
                                    hoverSrc={item.hoverImage}
                                    className="GiftImage object-contain w-full h-full rounded-md mb-6"
                                />
                            </div>
                            <p className='giftName text-center  mb-2'> {item.name} </p>
                            {item.discount > 0 ? (
            <p className="flex justify-center items-center gap-2">
              <span className="text-xs transform transition-all duration-300 group-hover:scale-110">
                ₹{Math.floor(item.price*(1-item.discount/100)).toFixed(2)}
              </span>
              <span className="text-gray-500 line-through text-xs">
                ₹{item.price}
              </span>
            </p>
          ) : (
            <p className="text-xl transform transition-all duration-300 group-hover:scale-110">
              ₹{item.price}
            </p>
          )}
                        </div>
                    </Link>
                ))}

            </div>
        </div>
    )
}
export default GiftSets