import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import Cart from './Cart';
import CartPageFaq from './CartPageFaq';
import CartTotal from './CartTotal';
import EmptyCart from './EmptyCart';
import MobileNav from './MobileNav';
import Footer from './SPFooter';
import {toast,Toaster} from 'react-hot-toast';
// Loading Spinner Component
const LoadingSpinner = () => {
    return (
        <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );
};

const CartHold = () => {
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCartDetails();
    }, []);

    // Add artificial delay helper
    const addDelay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const fetchCartDetails = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await addDelay(500); // Add 1 second delay
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                toast.error('Please log in...', {
                    duration: 2000,
                    style: {
                        background: '#fff',
                        color: '#333',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }
                });
                
                
                //   setTimeout(()=>{
                //     navigate('/login')
                //   },1000)
                // // throw new Error('User not logged in');
                return; 
            }
            const idToken = await getIdToken(user);
            const response = await axios.get('https://www.campuscart.co/api/cart', {
                headers: { 'Authorization': `Bearer ${idToken}` }
            });
            setCartItems(response.data.cartItems);
            setTotalPrice(response.data.totalPrice);
        } catch (error) {
            console.error('Error fetching cart details:', error);
            setError('Failed to fetch cart details. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateCart = async () => {
        setIsLoading(true);
        try {
            await addDelay(100); // Add 1 second delay before fetching
            await fetchCartDetails();
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewOrders = () => {
        navigate('/orders');
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-white">
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-lg text-red-600">{error}</div>
            </div>
        );
    }

    const cartLen = cartItems.length;

    return (
        <div className="min-h-screen bg-white">
            <MobileNav />
            <Toaster
                position="bottom-center"
                toastOptions={{
                    // Default options for all toasts
                    className: '',
                    success: {
                        iconTheme: {
                            primary: '#4CAF50',
                            secondary: '#fff',
                        },
                    },
                    error: {
                        iconTheme: {
                            primary: '#EF4444',
                            secondary: '#fff',
                        },
                    },
                }}
            />
            {cartLen === 0 ? (
                <EmptyCart />
            ) : (
                <div className="flex flex-col min-h-screen">
                    <div className="flex-grow">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
                                <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                                    <h1 className="text-2xl sm:text-3xl font-medium">Your Cart</h1>
                                    <button
                                        onClick={handleViewOrders}
                                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        View Orders
                                    </button>
                                </div>
                                <p className="text-lg sm:text-xl font-semibold mt-2 sm:mt-0">
                                    {cartLen} {cartLen === 1 ? 'item' : 'items'}
                                </p>
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                                <div className="lg:col-span-8">
                                    <div className="space-y-6">
                                        {cartItems.map((item) => (
                                            <div key={item.id} className="border-b border-gray-200 pb-6">
                                                <Cart
                                                    item={{
                                                        id: item.id,
                                                        title: item.title,
                                                        quantity: item.quantity,
                                                        total: item.total,
                                                        price: item.price,
                                                        image: item.image,
                                                        cartLength: cartLen,
                                                        size: item.size,
                                                        color: item.color
                                                    }}
                                                    onUpdateCart={handleUpdateCart}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="lg:col-span-4">
                                    <div className="bg-white rounded-lg border border-gray-200 p-6">
                                        <CartTotal totalPr={totalPrice} items={cartItems} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <CartPageFaq />
                        </div>
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
};

export default CartHold;