import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import {useCartCount} from "../context/CartContext"
const ProductModal = ({ product, isOpen, onClose, onAddToCart }) => {
  const [selectedColorVariant, setSelectedColorVariant] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
 const {cartCount,updateCartCount}=useCartCount()
  // Initialize selected color variant when modal opens
  useEffect(() => {
    if (product?.colorVariants?.length > 0) {
      setSelectedColorVariant(product.colorVariants[0]);
      setSelectedSize('');
      setCurrentImageIndex(0);
    }
  }, [product]);

  if (!isOpen || !product) return null;

  const handleColorChange = (colorVariant) => {
    setSelectedColorVariant(colorVariant);
    setCurrentImageIndex(0);
    setSelectedSize(''); // Reset size selection when color changes
  };

  const isSizeAvailable = (size) => {
    if (!selectedColorVariant) return false;
    const sizeInfo = selectedColorVariant.availableSizes.find(s => s.size === size);
    return sizeInfo?.inStock || false;
  };

  const handleAddToCart = () => {
    if (!selectedSize) {
      alert('Please select a size');
      return;
    }
    onAddToCart({
      ...product,
      color: selectedColorVariant.name,
      size:selectedSize,
      quantity
    });
    updateCartCount(cartCount+quantity)
    onClose();
  };

  const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-auto">
        <div className="p-4 flex justify-end">
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-6">
          {/* Image Section */}
          <div className="space-y-4">
            <div className="aspect-square overflow-hidden rounded-lg">
              {selectedColorVariant && (
                <img
                  src={currentImageIndex<selectedColorVariant.images.length?selectedColorVariant.images[currentImageIndex]:"https://lh7-us.googleusercontent.com/vgQQ6RHRXE-ulkH8R878Szun3nkF20HGizmLfGA_pHlANODl01WDbZr0qyfWRH0V-rvHHNgheP-bBLHTl8Vu6yCx-tYcCIX8TukbGZ2uC9pSNSKG2TUdN3mP2yvl0dprEmZ22vKOigOMP-Jqf5EbZpbhx44EXg"}
                  alt={`${product.name} in ${selectedColorVariant.name}`}
                  className="w-full h-full object-contain"
                />
              )}
            </div>
            {selectedColorVariant && (
              <div className="flex gap-2 overflow-auto py-2">
                {selectedColorVariant.images.map((img, idx) => (
                  <button
                    key={idx}
                    onClick={() => setCurrentImageIndex(idx)}
                    className={`flex-shrink-0 w-20 h-20 rounded-md overflow-hidden border-2 ${
                      currentImageIndex === idx ? 'border-blue-500' : 'border-gray-200'
                    }`}
                  >
                    <img src={img} alt="" className="w-full h-full object-contain" />
                  </button>
                ))}
                <button
                    key={selectedColorVariant.images.length}
                    onClick={() => setCurrentImageIndex(selectedColorVariant.images.length)}
                    className={`flex-shrink-0 w-20 h-20 rounded-md overflow-hidden border-2 ${
                      currentImageIndex === selectedColorVariant.images.length ? 'border-blue-500' : 'border-gray-200'
                    }`}
                  >
                    <img src={product.type=='tshirt'?"https://lh7-us.googleusercontent.com/esT4N_vQY6hylb12egHSl5hZWP0DV4MXcsgg4P0Xkw3JuXwmG_BaP1ra2h8m6fb71iNCOy4j2LOrrV08Apyd6IVgwJYM_s2XmsqtOplKMblINgQsApAhiLP7cdk0vU7BK-zrxjvoPj_Jr2ZLnHKYxHjGqTIauQ":""} alt="" className="w-full h-full object-contain" />
                  </button>
              </div>
            )}
          </div>

          {/* Details Section */}
          <div className="space-y-6">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">{product.name}</h2>
              <p className="text-sm text-gray-500">{product.institute}</p>
            </div>

            <div className="space-y-1">
              <p className="text-lg font-medium text-gray-900">
                {product.discount > 0 ? (
                  <>
                    <span className="line-through text-gray-500 mr-2">
                      ₹{product.price.toFixed(2)}
                    </span>
                    <span className="text-red-500">
                      ₹{Math.floor(product.price * (1 - product.discount / 100)).toFixed(2)}
                    </span>
                  </>
                ) : (
                  `₹${product.price.toFixed(2)}`
                )}
              </p>
              {product.discount > 0 && (
                <span className="inline-block bg-red-100 text-red-800 text-xs font-medium px-2 py-1 rounded">
                  {Math.ceil(product.discount)}% OFF
                </span>
              )}
            </div>

            {/* Color Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Color
              </label>
              <div className="flex flex-wrap gap-2">
                {product.colorVariants.map((colorVariant) => (
                  <button
                    key={colorVariant.value}
                    onClick={() => handleColorChange(colorVariant)}
                    className={`w-8 h-8 rounded-full border-2 ${
                      selectedColorVariant?.value === colorVariant.value
                        ? 'border-blue-500'
                        : 'border-gray-200'
                    }`}
                    style={{ backgroundColor: colorVariant.value }}
                    title={colorVariant.name}
                  />
                ))}
              </div>
            </div>

            {/* Size Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Size
              </label>
              <div className="flex flex-wrap gap-2">
                {sizes.map((size) => (
                  <button
                    key={size}
                    onClick={() => isSizeAvailable(size) && setSelectedSize(size)}
                    className={`
                      px-4 py-2 rounded-md text-sm font-medium
                      ${!isSizeAvailable(size) 
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed relative overflow-hidden'
                        : selectedSize === size
                        ? 'bg-gray-900 text-white'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }
                    `}
                    disabled={!isSizeAvailable(size)}
                  >
                    {size}
                    {!isSizeAvailable(size) && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-full h-0.5 bg-gray-400 rotate-45 transform origin-center"></div>
                      </div>
                    )}
                  </button>
                ))}
              </div>
            </div>

            {/* Quantity */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Quantity
              </label>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setQuantity(Math.max(1, quantity - 1))}
                  className="p-2 rounded-md bg-gray-100 hover:bg-gray-200"
                >
                  -
                </button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                  className="w-16 text-center border rounded-md p-2"
                />
                <button
                  onClick={() => setQuantity(quantity + 1)}
                  className="p-2 rounded-md bg-gray-100 hover:bg-gray-200"
                >
                  +
                </button>
              </div>
            </div>

            {/* Add to Cart Button */}
            <button
              onClick={handleAddToCart}
              className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors duration-200"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;