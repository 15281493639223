import React, { useState, useEffect } from 'react';
import { 
  FaSearch, FaUniversity, FaVenusMars, FaTshirt, 
  FaSortAmountDown, FaFilter
} from 'react-icons/fa';

const FilterSection = ({ onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    institutes: [],
    categories: []
  });
  const [filters, setFilters] = useState({
    searchTerm: '',
    institute: 'all',
    gender: 'all',
    itemType: 'all',
    sortBy: 'default'
  });

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  const fetchFilterOptions = async () => {
    try {
      // Fetch both types of options in parallel
      const [institutesResponse, categoriesResponse] = await Promise.all([
        fetch('https://www.campuscart.co/api/filters?type=institute'),
        fetch('https://www.campuscart.co/api/filters?type=category')
      ]);

      const institutes = await institutesResponse.json();
      const categories = await categoriesResponse.json();

      setFilterOptions({
        institutes,
        categories
      });
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className="w-full px-4 py-6 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        {/* Mobile Filter Button */}
        <button
          className="md:hidden w-full flex items-center justify-center gap-2 bg-blue-600 text-white py-3 rounded-lg mb-4"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaFilter />
          <span>Filter Products</span>
        </button>

        {/* Filter Section */}
        <div className={`${isOpen ? 'block' : 'hidden'} md:block`}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {/* Search */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-400 group-hover:text-blue-500 transition-colors" />
              </div>
              <input
                type="text"
                name="searchTerm"
                placeholder="Search items..."
                value={filters.searchTerm}
                onChange={handleFilterChange}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all bg-white"
              />
            </div>

            {/* Institute Select */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaUniversity className="text-gray-400 group-hover:text-blue-500 transition-colors" />
              </div>
              <select
                name="institute"
                value={filters.institute}
                onChange={handleFilterChange}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all appearance-none bg-white cursor-pointer"
              >
                <option value="all">All Institutes</option>
                {filterOptions.institutes.map(institute => (
                  <option key={institute._id} value={institute.value}>
                    {institute.name}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>

            {/* Gender Select */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaVenusMars className="text-gray-400 group-hover:text-blue-500 transition-colors" />
              </div>
              <select
                name="gender"
                value={filters.gender}
                onChange={handleFilterChange}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all appearance-none bg-white cursor-pointer"
              >
                <option value="all">All Genders</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unisex">Unisex</option>
              </select>
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>

            {/* Category Select */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaTshirt className="text-gray-400 group-hover:text-blue-500 transition-colors" />
              </div>
              <select
                name="itemType"
                value={filters.itemType}
                onChange={handleFilterChange}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all appearance-none bg-white cursor-pointer"
              >
                <option value="all">All Categories</option>
                {filterOptions.categories.map(category => (
                  <option key={category._id} value={category.value}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>

            {/* Sort Select */}
            <div className="relative group">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <FaSortAmountDown className="text-gray-400 group-hover:text-blue-500 transition-colors" />
              </div>
              <select
                name="sortBy"
                value={filters.sortBy}
                onChange={handleFilterChange}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all appearance-none bg-white cursor-pointer"
              >
                <option value="default">Sort By</option>
                <option value="price_low">Price: Low to High</option>
                <option value="price_high">Price: High to Low</option>
                <option value="newest">Newest First</option>
                <option value="popular">Most Popular</option>
              </select>
              <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;