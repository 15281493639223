import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import "../styles/Accordon.css";

const SinglePageFAQ = ({ item }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
    console.log(item)
  };

  return (
    <div className="accordion">
      <div className="accordion-item">
        <button
          className={`accordion-button ${
            activeAccordion === 0 ? 'active' : ''
          }`}
          onClick={() => toggleAccordion(0)}
        >
          <span className="flex-1 text-left">PRODUCT INFO</span>
          {activeAccordion === 0 ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
        <div
          className={`accordion-panel ${
            activeAccordion === 0 ? 'show' : ''
          }`}
        >
          {item.description}
        </div>
      </div>

      <div className="accordion-item">
        <button
          className={`accordion-button ${
            activeAccordion === 1 ? 'active' : ''
          }`}
          onClick={() => toggleAccordion(1)}
        >
          <span className="flex-1 text-left">HOW TO USE</span>
          {activeAccordion === 1 ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
        <div
          className={`accordion-panel ${
            activeAccordion === 1 ? 'show' : ''
          }`}
        >
          {item.howToUse
            ? item.howToUse
            : 'To keep your garment looking its best, wash inside out on a gentle cycle with cold water. Avoid bleach, tumble dry on low, and iron on reverse if needed. Enjoy long-lasting style'}
        </div>
      </div>

      <div className="accordion-item">
        <button
          className={`accordion-button ${
            activeAccordion === 2 ? 'active' : ''
          }`}
          onClick={() => toggleAccordion(2)}
        >
          <span className="flex-1 text-left">THE INGREDIENTS</span>
          {activeAccordion === 2 ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
        <div
          className={`accordion-panel ${
            activeAccordion === 2 ? 'show' : ''
          }`}
        >
          {item.ingredients}
        </div>
        
      </div>
      <div className="accordion-item">
        <button
          className={`accordion-button ${
            activeAccordion === 3 ? 'active' : ''
          }`}
          onClick={() => toggleAccordion(3)}
        >
          <span className="flex-1 text-left">Size Guide</span>
          {activeAccordion === 3 ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </button>
        <div
          className={`accordion-panel ${
            activeAccordion === 3 ? 'show' : ''
          }`}
        >
          <img
          src={item.type=='tshirt'?"https://lh7-us.googleusercontent.com/vgQQ6RHRXE-ulkH8R878Szun3nkF20HGizmLfGA_pHlANODl01WDbZr0qyfWRH0V-rvHHNgheP-bBLHTl8Vu6yCx-tYcCIX8TukbGZ2uC9pSNSKG2TUdN3mP2yvl0dprEmZ22vKOigOMP-Jqf5EbZpbhx44EXg":""}
          className="w-full h-full object-contain hover:opacity-90 transition-opacity duration-200"
        />
        </div>
        
      </div>
    </div>
  );
};

export default SinglePageFAQ;