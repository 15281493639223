import React from 'react';
import one from "../assets/one.png"
import two from "../assets/two.png"
import three from "../assets/three.png"
const HeroSection = () => {
  const features = [
    {
      image: one,
      title: "Powered By Nature",
      description: "Fine-Grained high quality fabric"
    },
    {
      image: two,
      title: "Exceptional Quality",
      description: "Designed to bring unmatched comfort, durability, and style to your wardrobe."
    },
    {
      image: three,
      title: "Skin First Formulas",
      description: "Comfort and skin friendly materials"
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12 md:py-20">
      {/* Main Heading */}
      <h1 className="text-3xl md:text-4xl lg:text-5xl text-center font-semibold mb-8 md:mb-12">
        WELCOME TO OUR STORE
      </h1>

      {/* Description */}
      <p className="text-lg md:text-xl text-center max-w-2xl mx-auto mb-16 text-gray-700">
        The Clothing brand that's setting a new standard. To help
        you feel good in your own body.
      </p>

      {/* Features Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 max-w-6xl mx-auto">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* Image */}
            <div className="mb-6 transition-transform duration-300 hover:scale-110">
              <img 
                src={feature.image} 
                alt={feature.title}
                className="w-24 md:w-28 lg:w-32 h-auto object-contain"
              />
            </div>

            {/* Title */}
            <h3 className="text-lg md:text-xl font-medium mb-4">
              {feature.title}
            </h3>

            {/* Description */}
            <p className="text-sm md:text-base text-gray-600 leading-relaxed">
              {feature.description}
            </p>
          </div>
        ))}
      </div>

      {/* Optional Decorative Elements */}
      <div className="absolute top-0 left-0 w-32 h-32 -z-10 opacity-10 bg-gradient-to-r from-pink-200 to-pink-100 rounded-full blur-2xl" />
      <div className="absolute bottom-0 right-0 w-32 h-32 -z-10 opacity-10 bg-gradient-to-r from-pink-200 to-pink-100 rounded-full blur-2xl" />
    </div>
  );
};

export default HeroSection;