import React, { useState, useEffect } from 'react'
import u20bg from "../assets/u20bg.png";
import ProductModal from './ProductModel';
// import { Breadcrumb } from '@chakra-ui/react';
import FilterSection from './Filters';
import { Link } from 'react-router-dom';
import JournalSection from "./JournalSection"
import { FaHeadset, FaShippingFast } from "react-icons/fa"
import { FaLock } from 'react-icons/fa';
import {   FaRegHeart } from 'react-icons/fa';
import { Toaster, toast } from 'react-hot-toast';
import { FaStar } from 'react-icons/fa';
import SPFooter from './SPFooter';
// import { useDispatch } from 'react-redux';
import axios from 'axios';

// Assume we have updated the store to include clothes items
// import { store } from "../productsStore/ClothesStore";
import { FaShoppingCart, FaHeart } from 'react-icons/fa'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const LoadingSpinner = () => {
  return (
      <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
  );
};
const ClothesStore = () => {
    const [filters, setFilters] = useState({
        searchTerm: '',
        institute: 'all',
        gender: 'all',
        itemType: 'all'
    });
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [filteredItems, setFilteredItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    // const dispacth = useDispatch();
    
   
    const [user, setUser] = useState(null);
    const auth = getAuth();
  
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  
          if(currentUser)
            setUser(currentUser);
        });
  
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);
  
    // Function to get the Firebase ID token
    const getIdToken = async () => {
        if (user) {
            try {
                return await user.getIdToken();
            } catch (error) {
                console.error('Error getting ID token:', error);
                return null;
            }
        }
        return null;
    }; 
    const addItemToCartHandler = async (item) => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            toast.error('Please log in to add items to your cart', {
                duration: 3000,
                style: {
                    background: '#fff',
                    color: '#333',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }
            });
            return;
        }


        try {
            const idToken = await getIdToken(user);
            const response = await axios.post(
                'https://www.campuscart.co/api/cart/add',
                {
                    itemId: item._id,
                    quantity: item.quantity,
                    price: item.price,
                    name: item.name,
                    image: item.primaryImage,
                    size:item.size,
                    color:item.color
                },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Accept':'application/json'
                    }
                }
            );

            if (response.status === 200) {
                toast.success('Item added to cart successfully', {
                    duration: 3000,
                    style: {
                        background: '#fff',
                        color: '#333',
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }
                });
                // You might want to update some global state or trigger a re-fetch of the cart here
            }
        } catch (error) {
            console.error('Error adding item to cart:', error);
            toast.error(error.response?.data?.message || 'Failed to add item to cart', {
                duration: 3000,
                style: {
                    background: '#fff',
                    color: '#333',
                    padding: '16px',
                    borderRadius: '8px',
                    width:'200px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }
            });
        }
    };



    const ProductCard = ({ item, onKnowMore, onAddToCart }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [isFavorite, setIsFavorite] = useState(false);
        
        const discountedPrice = item.discount > 0 
            ? (Math.floor(item.price * (1 - item.discount / 100)).toFixed(2))
            : item.price.toFixed(0);

        return (
            <div 
                className="group relative w-full sm:w-[calc(50%-1rem)] md:w-[calc(33.333%-1rem)] lg:w-72 
                         bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 
                         hover:shadow-2xl m-2 sm:m-3"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {/* Image Container - Reduced height for mobile */}
                <div className="relative h-48 sm:h-56 md:h-64 bg-gray-200 overflow-hidden">
                    <Link to={`/${item._id}`}>
                        <img
                            src={isHovered ? item.hoverImage : item.primaryImage}
                            alt={item.name}
                            className="w-full h-full object-contain transition-transform duration-700 group-hover:scale-110"
                        />
                    </Link>
                    
                    {/* Floating Badges - Smaller on mobile */}
                    <div className="absolute top-2 left-2 flex flex-col gap-1.5">
                        {item.isNew && (
                            <span className="bg-emerald-500 text-white px-2 py-0.5 rounded-full text-xs font-semibold">
                                NEW
                            </span>
                        )}
                        {item.discount > 0 && (
                            <span className="bg-red-500 text-white px-2 py-0.5 rounded-full text-xs font-semibold">
                                {Math.ceil(item.discount)}% OFF
                            </span>
                        )}
                    </div>

                    {/* Quick Action Buttons - Smaller on mobile */}
                    <div className={`absolute right-2 transition-all duration-300 ${isHovered ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4'}`}>
                        <div className="flex flex-col gap-1.5">
                            <button 
                                onClick={() => setIsFavorite(!isFavorite)}
                                className="p-1.5 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors duration-200"
                            >
                                {isFavorite ? (
                                    <FaHeart className="w-4 h-4 text-red-500" />
                                ) : (
                                    <FaRegHeart className="w-4 h-4 text-gray-600 hover:text-red-500" />
                                )}
                            </button>
                            <button 
                                onClick={() => handleDialog(item)}
                                className="p-1.5 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors duration-200"
                            >
                                <FaShoppingCart className="w-4 h-4 text-gray-600 hover:text-blue-500" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Content - Reduced padding for mobile */}
                <div className="p-2.5 sm:p-3">
                    <span className="inline-block px-2 py-0.5 bg-gray-100 text-gray-600 rounded-full text-xs font-medium mb-1.5">
                        {item.institute}
                    </span>

                    <Link to={`/${item._id}`}>
                        <h3 className="font-bold text-sm sm:text-base mb-1 text-gray-800 line-clamp-1 hover:text-blue-600">
                            {item.name}
                        </h3>
                    </Link>
                    
                    <p className="text-xs text-gray-500 mb-2">
                        {item.type}
                    </p>

                    {/* Price Section - Adjusted spacing */}
                    <div className="flex items-center gap-1.5 mb-3">
                        {item.discount > 0 && (
                            <span className="text-gray-400 line-through text-xs">
                                ₹{item.price.toFixed(2)}
                            </span>
                        )}
                        <span className="text-base sm:text-lg font-bold text-gray-900">
                        ₹{discountedPrice}
                        </span>
                    </div>

                    {/* Action Buttons - Smaller on mobile */}
                    <div className="grid grid-cols-5 gap-1.5">
                        <button 
                            onClick={() => handleDialog(item)}
                            className="col-span-2 bg-gray-100 hover:bg-gray-200 text-gray-800 
                                     font-medium py-1.5 px-2 rounded-lg text-xs sm:text-sm 
                                     transition-colors duration-200 flex items-center justify-center gap-1"
                        >
                            <FaShoppingCart className="w-3 h-3" />
                            Cart
                        </button>
                        <Link 
                            to={`/${item._id}`}
                            className="col-span-3 bg-blue-600 hover:bg-blue-700 text-white 
                                     font-medium py-1.5 px-2 rounded-lg text-xs sm:text-sm 
                                     transition-colors duration-200 flex items-center justify-center"
                        >
                            Know More
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

const [selectedProduct, setSelectedProduct] = useState(null);
const [isModalOpen, setIsModalOpen] = useState(false);
useEffect(() => {
    // Reset items and page when filters change
    setItems([]);
    setPage(1);
    setHasMore(true);
    fetchItems(1, true);
}, [filters]);

const fetchItems = async (pageNum, isNewSearch = false) => {
    const loadingState = isNewSearch ? setIsLoading : setIsLoadingMore;
    loadingState(true);
    try {
        const response = await axios.get('https://www.campuscart.co/api/items', {
            params: {
                ...filters,
                page: pageNum,
                limit: 20
            }
        });
        
        const newItems = response.data.items;
        setHasMore(response.data.hasMore);
        
        if (isNewSearch) {
            setItems(newItems);
        } else {
            setItems(prevItems => [...prevItems, ...newItems]);
        }
    } catch (error) {
        console.error('Error fetching items:', error);
    }
    loadingState(false);
};

const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchItems(nextPage, false);
};

const handleDialog = (item) => {
    console.log(item)
    setSelectedProduct(item);
    setIsModalOpen(true);
};

const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };
  


    const bgAddHandler = (e) => {
        e.target.classList.add("whi");
    }

    const bgRemoveHandler = (e) => {
        e.target.classList.remove("whi");
    }

    return (
        <div className='min-h-screen w-full'>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    // Default options for all toasts
                    className: '',
                    success: {
                        iconTheme: {
                            primary: '#4CAF50',
                            secondary: '#fff',
                        },
                    },
                    error: {
                        iconTheme: {
                            primary: '#EF4444',
                            secondary: '#fff',
                        },
                    },
                }}
            />
            {selectedProduct && (
                <ProductModal
                    product={selectedProduct}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onAddToCart={addItemToCartHandler}
                />
            )}

            {/* Hero Section */}
            <div className='relative w-full h-[30vh] sm:h-[40vh] md:h-[50vh] overflow-hidden'>
                <img src={u20bg} className="w-full h-full object-cover" alt="Background" />
                <div className='absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-white'>
                    <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4'>
                        Campus Wardrobe
                    </h1>
                    <p className='text-sm sm:text-base md:text-lg text-center px-4'>
                        Shop our collection of university apparel and accessories.
                    </p>
                </div>
            </div>


            {/* Filters - Compact mobile design */}
            <FilterSection onFilterChange={handleFilterChange} />

            {/* Products Grid - Adjusted spacing */}
            <div className="flex flex-col items-center px-2 sm:px-3 mt-3 mb-12 sm:mb-16">
                <div className="flex flex-wrap justify-center gap-2 sm:gap-3 w-full">
                    {items.map((item) => (
                        <ProductCard key={item._id} item={item} />
                    ))}
                </div>
                
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {isLoadingMore && <LoadingSpinner />}
                        {hasMore && !isLoadingMore && (
                            <button
                                onClick={handleLoadMore}
                                className="mt-8 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                                         transition-colors duration-200 font-medium"
                            >
                                Load More
                            </button>
                        )}
                        {!hasMore && items.length > 0 && (
                            <p className="mt-8 text-gray-600">No more items to load</p>
                        )}
                        {!hasMore && items.length === 0 && (
                            <p className="mt-8 text-gray-600">No items found</p>
                        )}
                    </>
                )}
            </div>  
            {/* Features Section - Reduced spacing on mobile */}
            {/* <div className="clear-both w-full"></div> */}

{/* Features Section - Added proper spacing */}
<div className='py-8 px-3 sm:py-10 sm:px-4 bg-white'>
    <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6 max-w-6xl mx-auto'>
        <div className='flex flex-col items-center text-center'>
            <FaShippingFast className='w-8 h-8 sm:w-12 sm:h-12 mb-2 sm:mb-4 text-gray-700' />
            <p className='uppercase text-xs sm:text-sm font-medium'>2 DAY DELIVERY</p>
        </div>
        <div className='flex flex-col items-center text-center'>
            <FaLock className='w-8 h-8 sm:w-12 sm:h-12 mb-2 sm:mb-4 text-gray-700' />
            <p className='uppercase text-xs sm:text-sm font-medium'>Secure Checkout</p>
        </div>
        <div className='flex flex-col items-center text-center'>
            <FaStar className='w-8 h-8 sm:w-12 sm:h-12 mb-2 sm:mb-4 text-gray-700' />
            <p className='uppercase text-xs sm:text-sm font-medium'>High Quality</p>
        </div>
        <div className='flex flex-col items-center text-center'>
            <FaHeadset className='w-8 h-8 sm:w-12 sm:h-12 mb-2 sm:mb-4 text-gray-700' />
            <p className='uppercase text-xs sm:text-sm font-medium'>Best Support</p>
        </div>
        {/* ... other feature items */}
    </div>
</div>

            {/* Journal Section - Added spacing */}
            <div className='py-8 sm:py-12 mt-100 sm:mt-100'>
                <div className="relative w-full">
                    <JournalSection />
                </div>
            </div>

            {/* Footer */}
            <SPFooter />

        </div>
    )
}


  
export default ClothesStore;