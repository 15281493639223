import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car1 from "../assets/car1.png"
import '../styles/slider.css'




export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      adaptiveHeight: false,
    };

    return (
      <div className="w-full max-w-7xl mx-auto px-4 mt-6">
        <div className="carousel-container">
          <Slider {...settings}>
            <div className="slide-wrapper">
              <div className="fixed-image-container">
                <img 
                  src={car1}
                  alt="Car 1"
                  className="carousel-image"
                />
              </div>
            </div>

            {/* <div className="slide-wrapper">
              <div className="fixed-image-container">
                <img 
                  src='https://img.freepik.com/free-psd/flat-design-fashion-template_23-2150028339.jpg'
                  alt="Car 2"
                  className="carousel-image"
                />
              </div>
            </div> */}

            {/* <div className="slide-wrapper">
              <div className="fixed-image-container">
                <img 
                  src={car3}
                  alt="Car 3"
                  className="carousel-image"
                />
              </div>
            </div> */}

            <div className="slide-wrapper">
              <div className="fixed-image-container">
                <img 
                  src="https://img.freepik.com/premium-photo/sale-50-text-modern-calligraphy-design-print-tshirt-minimalism_1091144-55506.jpg?w=1380"
                  alt="Car 4"
                  className="carousel-image"
                />
              </div>
            </div>

           

            
          </Slider>
        </div>
      </div>
    );
  }
}