import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      {/* Hero Section */}
      <div className="max-w-4xl mx-auto text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-lg text-gray-600">
          We'd love to hear from you. Please reach out using any of the following methods.
        </p>
      </div>

      {/* Contact Cards */}
      <div className="max-w-4xl mx-auto grid md:grid-cols-3 gap-8">
        {/* Email Card */}
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <div className="p-6">
            <div className="flex flex-col items-center text-center">
              <div className="p-3 bg-blue-100 rounded-full mb-4">
                <Mail className="h-6 w-6 text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold mb-2">Email</h2>
              <p className="text-gray-600">
                <a href="mailto:contact@company.com" className="text-blue-600 hover:underline">
                  campuscart057@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Phone Card */}
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <div className="p-6">
            <div className="flex flex-col items-center text-center">
              <div className="p-3 bg-green-100 rounded-full mb-4">
                <Phone className="h-6 w-6 text-green-600" />
              </div>
              <h2 className="text-xl font-semibold mb-2">Phone</h2>
              <p className="text-gray-600">
                <a href="tel:+919057423161" className="text-green-600 hover:underline">
                  +91 9057423161
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Address Card */}
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
          <div className="p-6">
            <div className="flex flex-col items-center text-center">
              <div className="p-3 bg-purple-100 rounded-full mb-4">
                <MapPin className="h-6 w-6 text-purple-600" />
              </div>
              <h2 className="text-xl font-semibold mb-2">Address</h2>
              <p className="text-gray-600">
              338 3MB sector 3<br />
              Indira Gandhi Nagar Jagatpura <br />
                Jaipur, Rajasthan 302017
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Map Placeholder
      <div className="max-w-4xl mx-auto mt-12">
        <div className="bg-white rounded-lg shadow-md p-4">
          <img 
            src="/api/placeholder/800/400" 
            alt="Map location" 
            className="w-full h-64 object-cover rounded-lg"
          />
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default ContactUs;