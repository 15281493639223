import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { ArrowRight } from 'lucide-react';
import Footer from './SPFooter';

const CategoryProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { type } = useParams();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const categories = [
    { id: 'all', name: 'All' },
    { id: 'shirt', name: 'Shirts' },
    { id: 'tshirt', name: 'T-Shirts' },
    { id: 'jeans', name: 'Jeans' },
    { id: 'jacket', name: 'Jackets' }
  ];

  const fetchProducts = async (page = 1, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }

      const response = await axios.get(
        `https://www.campuscart.co/api/items/category/${type}?page=${page}`
      );

      const { items, hasMore: moreItems } = response.data;

      if (isLoadMore) {
        setProducts(prev => [...prev, ...items]);
      } else {
        setProducts(items);
      }

      setHasMore(moreItems);
      setCurrentPage(page);
    } catch (err) {
      if (err.message !== "Request failed with status code 404") {
        setError(err.message);
      }
      if (!isLoadMore) {
        setProducts([]);
      }
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchProducts(1, false);
  }, [type]);

  const handleLoadMore = () => {
    fetchProducts(currentPage + 1, true);
  };

  const filteredProducts = activeFilter === 'all'
    ? products
    : products.filter(product => product.type === activeFilter);

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center min-h-screen text-red-500">
      Error: {error}
    </div>
  );

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white">
        {/* Decorative background pattern */}
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
          <div className="relative h-full">
            <svg
              className="absolute right-full translate-y-1/4 translate-x-1/4 transform lg:translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="pattern-squares"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#pattern-squares)" />
            </svg>
          </div>
        </div>

        <div className="relative pt-12 pb-16 sm:pb-24 md:pb-32 lg:pb-40 xl:pb-48">
          <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Discover Our</span>
                <span className="block text-black">
                  {capitalizeFirstLetter(type)} Collection
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                Explore our curated selection of {type} designed for comfort and style. 
                Each piece is crafted with attention to detail and quality materials.
              </p>
              <div className="mt-8 sm:mt-12 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#products"
                    className="flex items-center justify-center gap-2 rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white hover:bg-gray-900 md:px-10 md:py-4 md:text-lg"
                  >
                    Shop Now
                    <ArrowRight className="w-5 h-5" />
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#collection"
                    className="flex items-center justify-center gap-2 rounded-md border border-black px-8 py-3 text-base font-medium text-black hover:bg-gray-50 md:px-10 md:py-4 md:text-lg"
                  >
                    View Collection
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Products Section */}
      <div id="products" className="bg-gray-50 py-16 sm:py-24">
        <div className="container mx-auto px-4">
          {/* Category Filters */}
          <div className="mb-12">
            <div className="flex flex-wrap justify-center gap-4">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setActiveFilter(category.id)}
                  className={`px-6 py-2 text-sm font-medium transition-colors duration-300 border rounded-md
                    ${activeFilter === category.id 
                      ? 'bg-black text-white border-black' 
                      : 'bg-white text-black border-gray-300 hover:border-black'
                    }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>

          {/* Products Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {filteredProducts.map((item) => (
              <div 
                key={item._id} 
                className="group relative bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
              >
                <Link to={`/${item._id}`} className="block">
                  <div className="relative aspect-[7/8] overflow-hidden">
                    <img
                      src={item.primaryImage}
                      alt={item.name}
                      className="w-full h-full object-contain transition-transform duration-500 group-hover:scale-110"
                    />
                    <img
                      src={item.hoverImage}
                      alt={`${item.name} hover`}
                      className="absolute inset-0 w-full h-full object-contain opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-0 transition-opacity duration-300 group-hover:bg-opacity-10"></div>
                  </div>
                  
                  <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900 mb-2">
                      {item.name}
                    </h2>
                    
                    <div className="mt-4">
                      <div className="flex justify-between items-center">
                      <div className="flex items-center gap-1.5 mb-3">
                        {item.discount > 0 && (
                            <span className="text-gray-400 line-through text-M">
                                ₹{item.price.toFixed(2)}
                            </span>
                        )}
                        <span className="text-base sm:text-lg font-bold text-gray-900">
                        ₹{Math.floor(item.price*(1-item.discount/100)).toFixed(2)}
                        </span>
                    </div>
                        
                        <div className="relative">
                          <button className="px-6 py-2 bg-transparent border-2 border-black text-black text-sm font-medium transition-all duration-300 group-hover:bg-black group-hover:text-white rounded">
                            Know More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          {hasMore && filteredProducts.length > 0 && (
            <div className="flex justify-center mt-12">
              <button
                onClick={handleLoadMore}
                disabled={loadingMore}
                className="px-8 py-3 bg-black text-white rounded-md hover:bg-gray-900 transition-colors duration-300 disabled:bg-gray-400"
              >
                {loadingMore ? (
                  <div className="flex items-center gap-2">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                    Loading...
                  </div>
                ) : (
                  'Load More'
                )}
              </button>
            </div>
          )}

          {/* No Results Message */}
          {filteredProducts.length === 0 && (
            <div className="text-center py-16 text-gray-500">
              No products found in this category.
            </div>
          )}
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default CategoryProducts;