export function Button({ children, className = "", ...props }) {
    return (
      <button
        className={`px-4 py-2 rounded-md ${className}`}
        {...props}
      >
        {children}
      </button>
    )
  }
  
  // components/ui/input.jsx
  export function Input({ className = "", ...props }) {
    return (
      <input
        className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 ${className}`}
        {...props}
      />
    )
  }