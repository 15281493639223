import React from 'react'
import cartgif from "../assets/cartGif.gif";
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
// import "../styles/BestSellers.css";


const EmptyCart = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        setLoading(false);
      });
  
      // Cleanup subscription
      return () => unsubscribe();
    }, [auth]);
  
    if (loading) {
      return (
        <div className="flex items-center justify-center min-h-[50vh]">
          <div className="text-xl">Loading...</div>
        </div>
      );
    }
  
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] px-4 py-8 md:py-12">
        {user ? (
          // Logged in user view
          <>
            <div className="mb-8 text-center">
              <p className="text-xl md:text-3xl font-semibold uppercase tracking-wide">
                Cart Is Empty Maybe Order Something :)
              </p>
            </div>
            
            <div className="relative w-full max-w-sm md:max-w-md lg:max-w-lg">
              <img
                src={cartgif}
                alt="Empty Cart"
                className="w-full h-auto object-contain"
              />
            </div>
          </>
        ) : (
          // Not logged in view
          <div className="text-center space-y-6">
            <div className="space-y-2">
              <p className="text-xl md:text-2xl font-semibold text-gray-800">
                Please login to view your cart
              </p>
              <p className="text-gray-600">
                You need to be logged in to manage your shopping cart
              </p>
            </div>
            
            <Link 
              to="/login"
              className="inline-block px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 font-medium"
            >
              Go to Login
            </Link>
          </div>
        )}
      </div>
    );
  };
  
  export default EmptyCart;