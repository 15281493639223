import React from 'react';
// import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import {  toast } from 'react-hot-toast';
// import { cartActions } from '../redux-state/CartState';

const OurBestSellers = (props) => {
  const { title, price, id, image, discount, handleDialog } = props;
  // const dispatch = useDispatch();

  const calculateDiscountedPrice = () => {
    if (!discount) return price;
    const discountAmount = (price * discount) / 100;
    return (price - discountAmount).toFixed(2);
  };

  const addItemToCartHandler = async (item) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        toast.error('Please log in to add items to your cart', {
            duration: 3000,
            style: {
                background: '#fff',
                color: '#333',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }
        });
        return;
    }


    try {
        const idToken = await getIdToken(user);
        const response = await axios.post(
            'https://www.campuscart.co/api/cart/add',
            {
                itemId: item._id,
                quantity: item.quantity,
                price: item.price,
                name: item.name,
                image: item.primaryImage,
                size:item.size,
                color:item.color
            },
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Accept':'application/json'
                }
            }
        );

        if (response.status === 200) {
            toast.success('Item added to cart successfully', {
                duration: 3000,
                style: {
                    background: '#fff',
                    color: '#333',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }
            });
            // You might want to update some global state or trigger a re-fetch of the cart here
        }
    } catch (error) {
        console.error('Error adding item to cart:', error);
        toast.error(error.response?.data?.message || 'Failed to add item to cart', {
            duration: 3000,
            style: {
                background: '#fff',
                color: '#333',
                padding: '16px',
                borderRadius: '8px',
                width:'200px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }
        });
    }
};


  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1 group">
      <Link to={`/${id}`}>
        <div className="relative pb-[100%] overflow-hidden">
          {discount > 0 && (
            <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md z-10 transform transition-transform duration-300 hover:scale-110">
              -{Math.ceil(discount)}%
            </div>
          )}
          <img 
            src={image} 
            alt={title} 
            className="absolute top-0 left-0 w-full h-full object-contain p-4 transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300"></div>
        </div>
      </Link>
      <div className="p-4 text-center">
        <h2 className="text-lg font-medium mb-2 line-clamp-2 min-h-[3.5rem] group-hover:text-primary transition-colors duration-300">
          {title}
        </h2>
        <div className="mb-4 font-serif">
          {discount > 0 ? (
            <div className="flex justify-center items-center gap-2">
              <span className="text-xl text-primary transform transition-all duration-300 group-hover:scale-110">
                ₹{Math.floor(calculateDiscountedPrice()).toFixed(2)}
              </span>
              <span className="text-gray-500 line-through text-sm">
                ₹{price}
              </span>
            </div>
          ) : (
            <p className="text-xl transform transition-all duration-300 group-hover:scale-110">
              ₹{price}
            </p>
          )}
        </div>
        <Link to={`/${id}/#`}>
          <button 
            className="w-full bg-primary text-white py-2 px-4 rounded-md transition-all duration-300 
              hover:bg-primary/90 hover:shadow-md 
              active:transform active:scale-95
              focus:outline-none focus:ring-2 focus:ring-primary/50"
          >
            Know More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OurBestSellers;