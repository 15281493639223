import React, { useState, useEffect } from 'react';
import OurBestSellers from './OurBestSellers';
import ProductModal from './ProductModel';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch('https://www.campuscart.co/api/bestseller');
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }
            const data = await response.json();
            setProducts(data.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDialog = (item) => {
        setSelectedProduct(item);
        setIsModalOpen(true);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[200px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center text-red-500 py-4">
                Error loading products: {error}
            </div>
        );
    }

    return (
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Best Sellers</h2>
            
            {selectedProduct && (
                <ProductModal
                    product={selectedProduct}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onAddToCart={null}
                />
            )}
            
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6" id="bestSellers">
                {products.map((product) => (
                    <div key={product.id}>
                        <OurBestSellers
                            id={product.item.id}
                            title={product.item.name}
                            price={product.item.price}
                            image={product.item.primaryImage}
                            hoverImage={product.item.hoverImage}
                            discount={product.item.discount}
                            handleDialog={() => handleDialog(product)}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Products;