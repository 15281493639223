import React from 'react'
import "../styles/Navbar.css";
import { useState } from 'react';
// import BestSellers from './BestSellers';
import GiftSets from './GiftSets';
// import Body from './Body';
import { FaShoppingBag, FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import logo from "../assets/logo.png"
// import { useSelector } from 'react-redux';
import { FaTimes, FaBars } from 'react-icons/fa';
import { useCartCount } from '../context/CartContext';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const NavBar = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [user, setUser] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [cartCount, setCartCount] = useState(0);
  const { cartCount } = useCartCount();
  // console.log(cartCount)
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      
    });

    return () => unsubscribe();
  }, []);

 

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const showHandler = () => {
    setShow(true);
    setShow2(false);
    setShow3(false);
    setShow4(false);
  };

  const showHandler2 = () => {
    setShow2(true);
    setShow(false);
    setShow3(false);
    setShow4(false);
  };

  const showHandler3 = () => {
    setShow3(true);
    setShow(false);
    setShow2(false);
    setShow4(false);
  };

  const showHandler4 = () => {
    setShow4(true);
    setShow(false);
    setShow2(false);
    setShow3(false);
  };

  const dontShowHandler = () => {
    setShow(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <style>
        {`
          .mobile-menu-button {
            display: none;
            font-size: 24px;
            background: none;
            border: none;
            cursor: pointer;
            padding: 10px;
            position: absolute;
            right: 20px;
            top: 20px;
          }

          @media (max-width: 768px) {
            .mobile-menu-button {
              display: block;
            }

            .menuNav {
              display: none;
            }

            .menuNav.mobile-open {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 80px;
              left: 0;
              right: 0;
              background: white;
              padding: 20px;
              z-index: 1000;
              box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            }

            .menuNav.mobile-open li {
              margin: 10px 0;
              width: 100%;
              text-align: center;
            }

            .dropdown-nav {
              position: static !important;
              width: 100% !important;
              box-shadow: none !important;
              margin-top: 10px !important;
            }

            .navbar {
              padding: 10px 20px;
              position: relative;
            }

            .cart-icon {
              position: absolute !important;
              right: 70px !important;
              top: 25px !important;
            }

            .orders-icon {
              position: absolute !important;
              right: 120px !important;
              top: 25px !important;
            }

            .navLine {
              display: none;
            }

            .auth-links {
              flex-direction: column;
              align-items: center;
            }

            .auth-links a {
              margin: 5px 0;
            }

            .user-info {
              flex-direction: column;
              align-items: center;
            }
          }
            .cart-badge {
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: #ef4444;
            color: white;
            border-radius: 50%;
            padding: 2px 6px;
            font-size: 12px;
            min-width: 18px;
            text-align: center;
          }

          .cart-icon-container {
            position: relative;
            display: inline-block;
          }

          @media (max-width: 768px) {
            .cart-badge {
              top: -5px;
              right: -5px;
            }
          }
        `}
      </style>

      <header className="banner" role="banner">
        <nav className="navbar" role="navigation" aria-label="menu">
          <Link to="/">
            <img src={logo} className="ml-32 h-20" alt="Logo" />
          </Link>

          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>

          <ul className={`menuNav ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
            <li className="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler2}>
              Categories
              {show2 && (
                <ul className="dropdown-nav dropdown-nav2" onMouseLeave={dontShowHandler}>
                  <GiftSets />
                </ul>
              )}
            </li>

            <li className="dropdown nav-link nav-link-fade-up">
              <Link to="/shop">Shop</Link>
            </li>

            <p className="navLine absolute bg-red-600 w-1 font-extralight h-9 z-50"></p>

            <li className="nav-item auth-item">
              {user ? (
                <div className="user-info">
                  <span className="user-name">{user.displayName || user.email}</span>
                  <button onClick={handleLogout} className="logout-btn">
                    Logout
                  </button>
                </div>
              ) : (
                <div className="auth-links">
                  <Link to="/login" className="nav-link">Login/signup</Link>
                </div>
              )}
            </li>
            {user && (
            <Link to="/orders" className="orders-icon">
              <FaUser className="text-2xl" />
            </Link>
          )}
          </ul>

         

          <Link to="/cart" className="cart-icon-container">
            <FaShoppingBag className="text-2xl" />
            {cartCount > 0 && (
              <span className="cart-badge">
                {cartCount > 9 ? '9+' : cartCount}
              </span>
            )}
          </Link>
        </nav>
      </header>
    </div>
  );
};

export default NavBar;