import React from 'react';
import { Calendar, Clock, Share2, Bookmark, ChevronRight } from "lucide-react";

const JournalPage = () => {
  const relatedArticles = [
    {
      title: "The Evolution of College Streetwear",
      date: "April 15, 2024",
      readTime: "5 min read",
      image: "/api/placeholder/400/300",
      category: "Fashion History"
    },
    {
      title: "Sustainable Campus Fashion Guide",
      date: "April 12, 2024",
      readTime: "4 min read",
      image: "/api/placeholder/400/300",
      category: "Sustainability"
    },
    {
      title: "Game Day Style Essentials",
      date: "April 10, 2024",
      readTime: "6 min read",
      image: "/api/placeholder/400/300",
      category: "Style Guide"
    }
  ];

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <h1 className="text-2xl md:text-3xl font-serif text-center">THE CAMPUS CHRONICLE</h1>
          <p className="text-gray-500 text-center text-sm mt-2">Your Guide to College Style & Culture</p>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Article Header */}
        <article className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="relative">
            <img 
              src="https://media.istockphoto.com/id/824257318/photo/unrecognizable-teenage-students-in-high-school-hall.jpg?s=2048x2048&w=is&k=20&c=SscQD4LTQ5pxz4714OaAbKTSDDAcqjzyyjDf_t4k3Ks=" 
              alt="College Fashion" 
              className="w-full h-72 md:h-96 object-cover"
            />
            <div className="absolute top-4 left-4">
              <span className="bg-blue-600 text-white text-xs font-medium px-3 py-1 rounded-full">
                Style Guide
              </span>
            </div>
          </div>

          <div className="p-6 md:p-8">
            {/* Article Meta */}
            <div className="flex flex-wrap items-center text-sm text-gray-500 mb-6 space-x-4">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-2" />
                April 20, 2024
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-2" />
                8 min read
              </div>
              <div className="flex-grow"></div>
              <button className="flex items-center hover:text-blue-600">
                <Share2 className="w-4 h-4 mr-2" />
                Share
              </button>
              <button className="flex items-center hover:text-blue-600">
                <Bookmark className="w-4 h-4 mr-2" />
                Save
              </button>
            </div>

            {/* Article Title */}
            <h1 className="text-3xl md:text-4xl font-serif font-bold mb-6">
              The Ultimate Guide to Building Your College Wardrobe: Balancing Style, Comfort, and Identity
            </h1>

            {/* Author */}
            <div className="flex items-center mb-8">
              <img 
                src="/api/placeholder/80/80" 
                alt="Author" 
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <div className="font-medium">Tushar Sharma</div>
                <div className="text-sm text-gray-500">Fashion Editor</div>
              </div>
            </div>

            {/* Article Content */}
            <div className="prose prose-lg max-w-none">
              <p className="text-xl text-gray-600 mb-8 font-serif leading-relaxed">
                College fashion is more than just wearing your school's colors or sporting a logo hoodie. 
                It's about expressing your identity while navigating the unique demands of campus life, 
                from early morning lectures to late-night study sessions.
              </p>

              <h2 className="text-2xl font-serif font-bold mt-12 mb-6">
                The Foundation: Essential Pieces
              </h2>
              <p className="mb-6">
                Building a college wardrobe starts with versatile pieces that can transition seamlessly 
                from classroom to social events. Here's our curated list of essentials that combine 
                style, comfort, and practicality.
              </p>

              <div className="grid md:grid-cols-2 gap-6 my-8">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="font-serif font-bold mb-4">Daily Wear Essentials</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Classic college hoodies
                    </li>
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Comfortable graphic tees
                    </li>
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Versatile denim pieces
                    </li>
                  </ul>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="font-serif font-bold mb-4">Statement Pieces</h3>
                  <ul className="space-y-2">
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Varsity jackets
                    </li>
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Limited edition merch
                    </li>
                    <li className="flex items-center">
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2" />
                      Game day specials
                    </li>
                  </ul>
                </div>
              </div>

              <h2 className="text-2xl font-serif font-bold mt-12 mb-6">
                Styling Tips for Campus Life
              </h2>
              <div className="relative mb-8">
                <img 
                  src="/api/placeholder/800/400" 
                  alt="Styling Example" 
                  className="w-full rounded-lg"
                />
                <p className="text-sm text-gray-500 mt-2 text-center italic">
                  Modern college style combines comfort with personal expression
                </p>
              </div>
              <p className="mb-6">
                The key to mastering college fashion is understanding how to mix and match your pieces
                effectively. Consider these expert tips for creating looks that work for any campus occasion.
              </p>

              <div className="bg-blue-50 p-6 rounded-lg my-8">
                <h3 className="font-serif font-bold mb-4">Pro Styling Tips</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="text-blue-600 font-bold mr-2">1.</span>
                    Layer your college wear with basic pieces to create versatile outfits
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-600 font-bold mr-2">2.</span>
                    Invest in quality pieces that can withstand frequent wear and washing
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-600 font-bold mr-2">3.</span>
                    Mix branded items with personal pieces to create a unique style
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>

        {/* Related Articles */}
        <div className="mt-12">
          <h2 className="text-2xl font-serif font-bold mb-6">Related Articles</h2>
          <div className="grid md:grid-cols-3 gap-6">
            {relatedArticles.map((article, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm overflow-hidden">
                <img 
                  src={article.image} 
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <div className="text-xs text-blue-600 font-medium mb-2">
                    {article.category}
                  </div>
                  <h3 className="font-serif font-bold mb-2">
                    {article.title}
                  </h3>
                  <div className="flex items-center text-sm text-gray-500">
                    <Calendar className="w-4 h-4 mr-2" />
                    {article.date}
                    <Clock className="w-4 h-4 ml-4 mr-2" />
                    {article.readTime}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Newsletter */}
      <div className="bg-white border-t border-gray-200 mt-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
          <h3 className="text-2xl font-serif font-bold mb-4">Subscribe to Our Newsletter</h3>
          <p className="text-gray-600 mb-6">
            Get the latest style tips and campus fashion trends delivered to your inbox
          </p>
          <div className="max-w-md mx-auto flex gap-4">
            <input 
              type="email" 
              placeholder="Enter your email" 
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-medium transition duration-300">
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalPage;