import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { cartActions } from '../redux-state/CartState';
import BreadCrumb from './BreadCrumb';
import { AiFillStar,AiOutlineHeart } from "react-icons/ai";
import SinglePageFAQ from './SinglePageFAQ';
import AutoPlayMethods from './SingleAlsoLike';
import JournalSection from "./JournalSection";
import { FaShippingFast, FaLock } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import SPFooter from './SPFooter';
import { Toaster, toast } from 'react-hot-toast';
import { GiCardboardBoxClosed } from "react-icons/gi";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ProductModal from './ProductModel';
import {useNavigate } from 'react-router-dom';
import axios from 'axios';

const SinglePage = () => {
  const { id } = useParams();
  // const dispatch = useDispatch();
  // const toast = useToast();
  const [item, setItem] = useState(null);
  const [activeImg, setActiveImg] = useState(null);
  const [imgChange, setImgChange] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if(currentUser) setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  const getIdToken = async () => {
    if (user) {
      try {
        return await user.getIdToken();
      } catch (error) {
        console.error('Error getting ID token:', error);
        return null;
      }
    }
    return null;
  }; 

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const token = await getIdToken();
        
          const response = await axios.get(`https://www.campuscart.co/api/items/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setItem(response.data);
          setActiveImg(response.data.primaryImage);
        
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    };
    fetchItemDetails();
  }, [id, user]);

  const addItemToCartHandler = async (item) => {
    if (!user) {
      toast.error('Please log in to add items to your cart', {
        duration: 3000,
        style: {
            background: '#fff',
            color: '#333',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }
    });
      return;
    }

    try {
      const idToken = await getIdToken();
      const response = await axios.post(
        'https://www.campuscart.co/api/cart/add',
        {
          itemId: item._id,
          quantity: 1, // Default quantity, can be modified in modal
          price: item.price,
          name: item.name,
          image: item.primaryImage,
          size: item.size,
          color: item.color
        },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Accept': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        toast.success('Item added to cart successfully', {
          duration: 3000,
          style: {
              background: '#fff',
              color: '#333',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }
      });
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
      toast({
        title: 'Error',
        description: "Failed to add item to cart",
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleQuickAdd = () => {
    setIsModalOpen(true);
  };

  const imgSrcTarget = (e) => {
    setActiveImg(e.target.src);
    setImgChange(true);
  };

  const defaultImageSrc = () => {
    setActiveImg(item?.primaryImage);
    setImgChange(false);
  };

  if (!item) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const { name, price, discount,primaryImage, hoverImage, images } = item;
  let numOfRev = Math.floor(Math.random() * 4) + 1;
  let reviews = Math.floor(Math.random() * (999 - 100 + 1) + 100);

  const ImageContainer = ({ src, onClick, onMouseEnter, onMouseLeave, alt, className = '' }) => (
    <div className={`relative bg-gray-100 rounded-xl overflow-hidden ${className}`}>
      <div className="absolute inset-0">
        <img
          src={src}
          alt={alt}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="w-full h-full object-contain hover:opacity-90 transition-opacity duration-200"
        />
      </div>
      <div className="pt-[100%]" />
    </div>
  );
  return (
    <div>
       <Toaster
                position="bottom-center"
                toastOptions={{
                    // Default options for all toasts
                    className: '',
                    success: {
                        iconTheme: {
                            primary: '#4CAF50',
                            secondary: '#fff',
                        },
                    },
                    error: {
                        iconTheme: {
                            primary: '#EF4444',
                            secondary: '#fff',
                        },
                    },
                }}
            />
    <div className="container mx-auto px-4 pt-24 md:pt-36 max-w-7xl">
      {/* Breadcrumb */}
      <div className="bg-gray-50 py-3 px-4 rounded-lg mb-8">
        <BreadCrumb name={name} />
      </div>

      {/* Product Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-12" id="productSection">
        {/* Image Gallery */}
        <div className="space-y-6">
          {/* Main Image */}
          <div className="relative group">
            <div className="w-full h-[500px] md:h-[600px] bg-gray-100 rounded-2xl overflow-hidden">
              <img
                src={imgChange ? activeImg : primaryImage}
                className="w-full h-full object-contain"
                alt={name}
              />
              <button className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:scale-110 transition-transform">
                <AiOutlineHeart className="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </div>

          {/* Thumbnail Grid */}
          <div className="grid grid-cols-4 gap-4">
            
            {images.slice(0, 5).map((img, index) => (
              <ImageContainer
                key={index}
                src={img}
                alt={`Product thumbnail ${index + 1}`}
                onMouseEnter={imgSrcTarget}
                onMouseLeave={defaultImageSrc}
              />
            ))}
          </div>
        </div>

        {/* Product Info */}
        <div className="space-y-8 lg:px-6">
          <div className="space-y-4">
            <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">{name}</h1>
            <div className="flex items-center gap-1.5 mb-3">
                        {item.discount > 0 && (
                            <span className="text-gray-400 line-through text-M">
                                ₹{item.price.toFixed(2)}
                            </span>
                        )}
                        <span className="text-base sm:text-lg font-bold text-gray-900">
                        ₹{Math.floor(price*(1-discount/100)).toFixed(2)}
                        </span>
                    </div>
          </div>
          
          <div className="flex items-center gap-3">
            <div className="flex">
              {Array.from({ length: 5 }).map((_, index) => (
                <AiFillStar key={index} className="w-5 h-5 text-yellow-400" />
              ))}
            </div>
            {/* <p className="text-sm text-gray-600">{reviews} reviews</p> */}
          </div>

          <div className="p-6 bg-gray-50 rounded-xl space-y-4">
            <p className="font-semibold text-sm text-gray-900">Get High Quality At Low Price</p>
            <div className="flex items-center gap-2">
              <BsCurrencyDollar className="w-5 h-5 text-blue-600" />
              <p className="text-sm text-gray-600">Buy Now</p>
            </div>
          </div>

          <div className="space-y-4">
            <button
               onClick={handleQuickAdd}
              className="w-full py-4 px-6 bg-black text-white rounded-xl font-semibold hover:bg-gray-800 transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]"
            >
              ADD TO CART
            </button>
            
            <button className="w-full py-4 px-6 border-2 border-black text-black rounded-xl font-semibold hover:bg-gray-50 transition-colors">
              ADD TO WISHLIST
            </button>
          </div>

          {/* Product Features */}
          <div className="grid grid-cols-2 gap-6 py-6 border-t border-b border-gray-200">
            <div className="flex items-center gap-3">
              <FaShippingFast className="w-6 h-6 text-gray-600" />
              <div>
                <p className="text-sm font-semibold">Cheap Delivery</p>
                <p className="text-xs text-gray-500">5-7 business days</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <FaLock className="w-6 h-6 text-gray-600" />
              <div>
                <p className="text-sm font-semibold">Secure Payment</p>
                <p className="text-xs text-gray-500">Encrypted checkout</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <BsCurrencyDollar className="w-6 h-6 text-gray-600" />
              <div>
                <p className="text-sm font-semibold">Loyalty Rewards</p>
                <p className="text-xs text-gray-500">Earn points with purchase</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <GiCardboardBoxClosed className="w-6 h-6 text-gray-600" />
              <div>
                <p className="text-sm font-semibold">Easy Returns</p>
                <p className="text-xs text-gray-500">30-day return policy</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Sections */}
      <div className="space-y-12">
        <div className="py-12 border-t border-gray-200">
          <div className="max-w-3xl mx-auto">
            <SinglePageFAQ item={item}/>
          </div>
        </div>

        <div className="py-12 border-t border-gray-200">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">You May Also Like</h2>
          <div className="max-w-6xl mx-auto">
            <AutoPlayMethods />
          </div>
        </div>

        {/* <div className="py-12 border-t border-gray-200">
          <div className="max-w-4xl mx-auto">
            <Review1 starCalc={numOfRev} rev={reviews} />
          </div>
        </div>

        <div className="max-w-2xl mx-auto py-12 border-t border-gray-200">
          <SearchBox />
        </div> */}

        <div className="max-w-6xl mx-auto py-12 border-t border-gray-200">
          <JournalSection />
        </div>
      </div>

      {/* Footer */}
      
    </div>
    {isModalOpen && (
        <ProductModal
          product={item}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAddToCart={addItemToCartHandler}
        />
      )}
    <footer className="mt-12 border-t border-gray-200">
        <SPFooter />
      </footer>
    </div>
  );
};

export default SinglePage;