import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-6 flex justify-between items-center text-left hover:bg-gray-50 px-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-gray-900 text-lg">{question}</span>
        <span className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ${
          isOpen ? 'max-h-70 pb-6' : 'max-h-0'
        }`}
      >
        <p className="text-gray-600 text-base px-4 leading-relaxed">{answer}</p>
      </div>
    </div>
  );
};

const CartPageFaq = () => {
  const faqs = [
    {
      question: "How long will shipping take?",
      answer: `Orders are shipped within 7 days from the date of the order and/or payment or as per the delivery
date agreed at the time of order confirmation and delivering of the shipment, subject to courier company /
post office norms`
    },
    {
      question: "What is your return policy?",
      answer: `We offer refund / exchange within first 7 days from the date of your purchase. If 7 days have passed
since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become
eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as
you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale,
then the item may not be eligible for a return / exchange. Further, only such items are replaced by us
(based on an exchange request), if such items are found defective or damaged.
You agree that there may be a certain category of products / items that are exempted from returns or
refunds. Such categories of the products would be identified to you at the item of purchase. For exchange
/ return accepted request(s) (as applicable), once your returned product / item is received and inspected
by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further.
If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will
be processed in accordance with our policies.`
    },
    {
      question: "Can I modify my order after placing it?",
      answer: "Orders can be modified within 1 hour of placement. After that, please contact our customer service team for assistance with any changes."
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we ship to most international locations. Shipping costs and delivery times vary by destination and will be calculated at checkout."
    },
    {
      question: "How do I track my order?",
      answer: "Once your order ships, you'll receive a tracking number via email. You can also track your order through your account dashboard in orders page."
    }
  ];

  return (
    <div className="w-full max-w-4xl mx-auto mt-16">
      <h2 className="text-3xl font-semibold mb-8 px-4">Frequently Asked Questions</h2>
      <div className="bg-white rounded-lg border border-gray-200 divide-y divide-gray-200">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default CartPageFaq;