import * as React from "react"

export function Dialog({ open, onOpenChange, children }) {
  if (!open) return null;
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black/50" 
        onClick={() => onOpenChange(false)}
      />
      <div className="relative bg-white rounded-lg w-full max-w-md p-6 shadow-lg">
        {children}
      </div>
    </div>
  )
}

export function DialogContent({ children, className = "" }) {
  return (
    <div className={`relative ${className}`}>
      {children}
    </div>
  )
}

export function DialogHeader({ children }) {
  return (
    <div className="mb-4">
      {children}
    </div>
  )
}

export function DialogTitle({ children }) {
  return (
    <h2 className="text-lg font-semibold">
      {children}
    </h2>
  )
}
