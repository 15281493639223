import React from 'react'

import "../styles/FollowONIG.css";

import "react-alice-carousel/lib/alice-carousel.css";
import i1 from "../assets/i1.jpg";
import i2 from "../assets/i2.jpg";
import i3 from "../assets/i3.jpg";
import i4 from "../assets/i4.jpg";
import i5 from "../assets/i5.jpg";
import i6 from "../assets/i6.jpg";
import i7 from "../assets/i7.jpg";
import { ExternalLink } from 'react-external-link';
import Marquee from "react-fast-marquee";


const FollowONIG = () => {
  const images = [
    { src: i1, link: 'https://www.instagram.com/campuscart_23/reel/C-1bxbxIXDO/' },
    { src: i2, link: 'https://www.instagram.com/p/C_E-8CnNNi3/' },
    { src: i3, link: 'https://www.instagram.com/campuscart_23/reel/CzDaDGgBKvS/' },
    { src: i4, link: 'https://www.instagram.com/campuscart_23/reel/C8V-9rDpwF5/' },
    { src: i5, link: 'https://www.instagram.com/campuscart_23/reel/C-4PdFkNd7_/' },
    { src: i6, link: 'https://www.instagram.com/campuscart_23/reel/C-9al3xJfWd/' },
    { src: i7, link: 'https://www.instagram.com/campuscart_23/reel/C_kNaevuFgn/' },
    // { src: i8, link: 'https://www.instagram.com/reel/CkjTteAMPaZ/' },
    // { src: i9, link: 'https://www.instagram.com/reel/CklzQFcJW9a/' },
    // { src: i10, link: 'https://www.instagram.com/reel/CkwQD6KO1WP/' },
    // { src: i11, link: 'https://www.instagram.com/reel/Ckg4wazO9SQ/' },
  ];

  return (
    <div className="w-full px-4 py-8 md:py-12 lg:py-16">
      {/* Title - Responsive text size and spacing */}
      <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6 md:mb-8 lg:mb-10">
        FOLLOW US ON INSTAGRAM
      </h2>
      
      {/* Marquee Container - Added proper spacing and containment */}
      <div className="w-full overflow-hidden">
        <Marquee 
          pauseOnHover={true} 
          speed={100}
          gradient={false}
          className="py-4"
        >
          {images.map((image, index) => (
            <ExternalLink 
              href={image.link}
              key={index}
              className="inline-block px-2"
            >
              <img
                src={image.src}
                alt={`Instagram post ${index + 1}`}
                className="w-48 md:w-56 lg:w-60 rounded-2xl transition-transform duration-300 hover:scale-105"
              />
            </ExternalLink>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default FollowONIG;