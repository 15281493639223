import React from 'react';
import { Link } from 'react-router-dom';
import { MdAdd } from "react-icons/md";
import { RiSubtractFill } from "react-icons/ri";
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
// import toast from 'react-hot-toast';

const Cart = ({ item, onUpdateCart }) => {
    const { title, quantity, price, id, image,size,color} = item;

    const updateItemQuantity = async (newQuantity) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                console.error('User not logged in');
                
                return;
            }
            const idToken = await getIdToken(user);
            await axios.put(`https://www.campuscart.co/api/cart/update/${id}`,
                { quantity: newQuantity,size,color },
                { headers: { 'Authorization': `Bearer ${idToken}` } }
            );
            onUpdateCart();
        } catch (error) {
            console.error('Error updating cart item:', error);
        }
    };

    const removeItemFromCartHandler = () => {
        updateItemQuantity(quantity - 1);
    };

    const addItemToCartHandler = () => {
        updateItemQuantity(quantity + 1);
    };

    return (
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-6">
            {/* Product Image */}
            <div className="w-full sm:w-1/4 md:w-1/5">
                <Link to={`/${id}`} className="block">
                    <img
                        src={image}
                        className="w-full h-auto rounded-lg object-cover"
                        alt={title}
                    />
                </Link>
            </div>

            {/* Product Details */}
            <div className="flex-1 space-y-2">
                <Link to={`/${id}`}>
                    <h3 className="text-lg sm:text-xl font-semibold line-clamp-2">
                        {`${title} ,${size},${color}` }
                    </h3>
                </Link>
                <p className="text-lg sm:text-xl font-medium">
                    ₹{price} 
                </p>
            </div>

            {/* Quantity Controls */}
            <div className="flex items-center bg-black text-white rounded-md p-2 w-32">
                <button
                    onClick={removeItemFromCartHandler}
                    className="p-1 hover:bg-gray-700 rounded"
                >
                    <RiSubtractFill className="text-2xl" />
                </button>
                <span className="flex-1 text-center text-lg">
                    {quantity}
                </span>
                <button
                    onClick={addItemToCartHandler}
                    className="p-1 hover:bg-gray-700 rounded"
                >
                    <MdAdd className="text-2xl" />
                </button>
            </div>
        </div>
    );
};

export default Cart;