import './App.css';
import { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from './firebase';  // Import auth from your firebase.js file
import NavBar from './components/NavBar';
import MobileNav from './components/MobileNav';
import SimpleSlider from './components/HeroCarousel';
import HeroSection from './components/HeroSection';
import Ingridients from './components/Ingridients';
import JournalSection from './components/JournalSection';
import SinglePage from "./components/SinglePage";
import JournalPage from "./components/JournalPage";
import CartHold from './components/CartHold';
import FollowONIG from './components/FollowONIG';
import ContactUs from './components/ContactUs';
// import  from 'react-hot-toast';
import { CartProvider } from './context/CartContext';
import Products from './components/Products';
import Footer from './components/SPFooter';
import Under10 from './components/Under10';
import ForHer from './components/ForHer';
import ScrollToTop from "./components/ScrollToTop";

import AnimatedLoginSignup from './components/Login';

import OrdersPage from './components/Orders';
const Login = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        navigate('/');  // Redirect to home page after successful login
      }).catch((error) => {
        console.error("Error during Google Sign-In:", error);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <button 
        onClick={handleGoogleSignIn}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign In with Google
      </button>
    </div>
  );
};

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    }); 

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <div className="App">
      <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav />
              <SimpleSlider />
              <HeroSection />
             
              <Products />
              <Ingridients />
              <JournalSection />
              <FollowONIG />
              <Footer />
            </>
          } />
          <Route path='/:id' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav />
              <SinglePage />
            </>
          } />
          <Route path='/journal/april' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav />
              <JournalPage />
            </>
          } />
          <Route path='/cart' element={
           <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav />
              <CartHold />
              </>
          } />
          
          <Route path='/category/:type' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav/>
              <Under10 />
            </>
          } />
          <Route path='/shop' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              <MobileNav />
              <ForHer/>
            </>
          }/>
          <Route path='/forher' element={
            <>
              <NavBar user={user} onLogout={handleLogout} />
              
              <ForHer />
            </>
          } />
          
          <Route path='/login' element={<AnimatedLoginSignup />} />
          <Route path='/orders' element={<OrdersPage/>}/>
          <Route path='/contactus' element={<><NavBar/><MobileNav/><ContactUs/><Footer/></>}></Route>
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
      </CartProvider>
     
    </div>
  );
}

export default App;