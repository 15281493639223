import React, { useState } from 'react';
import { ChevronRight, MoreHorizontal } from 'lucide-react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ items }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Show fewer items on mobile unless expanded
  const getVisibleItems = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 640 && !isExpanded) {
      if (items.length <= 2) return items;
      return [items[0], items[items.length - 1]];
    }
    return items;
  };

  const visibleItems = getVisibleItems();
  const hasHiddenItems = items.length > visibleItems.length;

  return (
    <div className="w-full px-4 md:px-6 py-2 bg-gray-100">
      <nav className="max-w-screen-xl mx-auto">
        <ol className="flex flex-wrap items-center space-x-2 text-sm">
          {visibleItems.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <ChevronRight className="w-4 h-4 text-gray-500 flex-shrink-0" />
              )}
              {index === 1 && hasHiddenItems && !isExpanded && (
                <button
                  onClick={() => setIsExpanded(true)}
                  className="flex items-center text-gray-500 hover:text-blue-600"
                >
                  <MoreHorizontal className="w-4 h-4" />
                </button>
              )}
              <li className="flex items-center">
                <BreadcrumbItem 
                  href={item.href} 
                  isLast={index === visibleItems.length - 1}
                >
                  {item.label}
                </BreadcrumbItem>
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </div>
  );
};

const BreadcrumbItem = ({ children, href, isLast }) => {
  const classes = `
    truncate max-w-[150px] sm:max-w-[200px] md:max-w-none
    hover:text-blue-600 transition-colors duration-200
    ${isLast ? 'text-gray-600 font-medium pointer-events-none' : 'text-gray-500'}
  `;

  if (isLast || href === '#') {
    return <span className={classes}>{children}</span>;
  }

  return (
    <Link to={href} className={classes}>
      {children}
    </Link>
  );
};

// Example usage component
const BreadcrumbWrapper = ({ name }) => {
  const items = [
    { label: 'Home', href: '/' },
    { label: name, href: '#' }
  ];

  return <Breadcrumb items={items} />;
};

export default BreadcrumbWrapper;