import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import CartAdditonalFeatures from './CartAdditonalFeatures';
import Features2 from './Features2';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/button";
import { getAuth, getIdToken } from 'firebase/auth';

const CartTotal = ({ totalPr,items }) => {
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [formData, setFormData] = useState({
        address: '',
        mobile: '',
        state: '',
        city: '',
        pincode: '',
        printname:''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    
    const shipping = 0;
    const tax = Math.floor(totalPr*(0.05));
    const finalPr = totalPr + shipping + tax;
    const cartItems =items;
    const cartLen = cartItems.length;

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setError('');
    };

    const validateForm = () => {
        if (!formData.address.trim()) return "Address is required";
        if (!formData.mobile.trim()) return "Mobile number is required";
        if (!formData.mobile.match(/^\d{10}$/)) return "Invalid mobile number";
        if (!formData.state.trim()) return "State is required";
        if (!formData.city.trim()) return "City is required";
        if (!formData.pincode.trim()) return "PIN code is required";
        if (!formData.pincode.match(/^\d{6}$/)) return "Invalid PIN code";
        return "";
    };

    const handlePhonePeSubmit = async () => {
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
    
        setIsLoading(true);
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                console.error('User not logged in');
                return;
            }
            const idToken = await getIdToken(user);
            const response = await fetch('https://www.campuscart.co/api/payment/getpaymentsession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    shippingAddress:{
                    address: formData.address,
                    mobile: formData.mobile,
                    state: formData.state,
                    city: formData.city,
                    pincode: formData.pincode,
                    printName:formData.printname
                    
                    },
                    amount: Math.round(finalPr)
                }),
            });
    
            if (!response.ok) {
                throw new Error('Payment session creation failed');
            }
    
            const data = await response.json();
            
            if (data.success) {
                setShowAddressPopup(false);
                window.location.href = data.paymentUrl;
            } else {
                setError(data.message || 'Failed to create payment session');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full bg-white p-6 rounded-lg">
            {/* Price Summary */}
            <div className="space-y-4">
                {/* Subtotal */}
                <div className="flex justify-between items-center text-lg font-semibold">
                    <span>Subtotal</span>
                    <span>₹{Math.round(totalPr)}</span>
                </div>

                {/* Shipping */}
                <div className="flex justify-between items-center text-md">
                    <span>Shipping</span>
                    <span>₹{shipping}</span>
                </div>

                {/* Tax */}
                <div className="flex justify-between items-center text-md">
                    <span>Tax</span>
                    <span>₹{tax}</span>
                </div>

                {/* Divider */}
                <div className="border-t border-gray-200 my-4"></div>

                {/* Total */}
                <div className="flex justify-between items-center text-xl font-semibold">
                    <span>Total</span>
                    <span>₹{Math.round(finalPr)}</span>
                </div>

                {/* Divider */}
                <div className="border-t border-gray-200 my-4"></div>
            </div>

            {/* Payment Button */}
            <div className="mt-6">
                <Button 
                    className="w-full bg-purple-600 hover:bg-purple-700 text-white py-3 text-lg"
                    onClick={() => setShowAddressPopup(true)}
                >
                    Pay with PhonePe
                </Button>
            </div>

            {/* Additional Features */}
            {/* {cartLen > 1 && (
                <div className="mt-8 hidden md:block">
                    <p className="text-center text-lg font-medium mb-4">
                        SECURELY CHECKOUT WITH
                    </p>
                    <CartAdditonalFeatures />
                    {cartLen > 2 && <Features2 />}
                </div>
            )} */}

            {/* Address Dialog */}
            <Dialog open={showAddressPopup} onOpenChange={setShowAddressPopup}>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle>Enter Delivery Details</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 mt-4">
                        {error && (
                            <div className="bg-red-50 text-red-600 p-3 rounded-md text-sm">
                                {error}
                            </div>
                        )}
                        <div className="space-y-4">
                            <Input
                                name="address"
                                placeholder="HostelName/Room.no/College"
                                value={formData.address}
                                onChange={handleInputChange}
                                className="w-full"
                            />
                            <Input
                                name="mobile"
                                placeholder="Mobile Number"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                type="tel"
                                maxLength={10}
                                className="w-full"
                            />
                            <Input
                                name="printname"
                                placeholder="print name(optional)"
                                value={formData.printname}
                                onChange={handleInputChange}
                                type="text"
                                className="w-full"
                            />
                            <div className="grid grid-cols-2 gap-4">
                                <Input
                                    name="state"
                                    placeholder="State"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    className="w-full"
                                />
                                <Input
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    className="w-full"
                                />
                            </div>
                            <Input
                                name="pincode"
                                placeholder="PIN Code"
                                value={formData.pincode}
                                onChange={handleInputChange}
                                maxLength={6}
                                className="w-full"
                            />
                        </div>
                        <Button 
                            className="w-full bg-purple-600 hover:bg-purple-700 text-white disabled:bg-purple-400 py-3"
                            onClick={handlePhonePeSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Proceed to Pay'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default CartTotal;